import { ApolloClient, ApolloProvider, HttpLink, InMemoryCache, split } from '@apollo/client'

import { getMainDefinition } from '@apollo/client/utilities'
import ActionCableLink from 'graphql-ruby-client/subscriptions/ActionCableLink'
import React from 'react'
import { isBrowser } from '../browser'

export default function Apollo({ children }) {
  if (!isBrowser) return null

  const { createConsumer } = require('@rails/actioncable')
  const token = typeof window !== "undefined" && window.localStorage.getItem("jwt-editor")

  console.log("connecting to ", process.env.GATSBY_ADMIN_API_ENDPOINT)

  const httpLink = new HttpLink({
    uri: process.env.GATSBY_ADMIN_API_ENDPOINT,
    headers: {
      authorization: `Bearer ${token}`
    }
  })

  const cable = createConsumer(`${process.env.GATSBY_ADMIN_API_CABLE_ENDPOINT}?token=${token}`)

  const splitLink = split(
    ({ query }) => {
      const definition = getMainDefinition(query)
      return (
        definition.kind === 'OperationDefinition' &&
        definition.operation === 'subscription'
      )
    },
    new ActionCableLink({ cable }),
    httpLink,
  )

  const cache = new InMemoryCache()
  const client = new ApolloClient({
    fetch,
    link: splitLink,
    cache
  })

  return (
    <ApolloProvider client={client}>
      {children}
    </ApolloProvider>
  )
}
